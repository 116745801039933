import data from '@emoji-mart/data';
import imageIconContent from '_assets/img/image_icon.png';
import pdfImageContent from '_assets/img/svg/pdf.svg';
import { ObserverLocator } from 'aurelia-binding';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { autoinject, bindable, computedFrom, customElement, LogManager } from 'aurelia-framework';
import { Picker } from 'emoji-mart';
import { ContactModel } from 'features/organisation/contact/contact-model';
import { ContactService } from 'features/organisation/contact/contact-service';
import { CONTACT_ACTIONS } from 'features/organisation/contact/contactcontroller/contact-controller-actions';
import { ConversationModel } from 'features/organisation/conversation/conversation-model';
import { ConversationService } from 'features/organisation/conversation/conversation-service';
import { InteractionModel } from 'features/organisation/conversation/interaction-model';
import { INTERACTION_ACTIONS } from 'features/organisation/conversation/interaction/interaction-actions';
import { DefaultDispositionCode, DispositionModel } from 'features/organisation/organisation/dispositioncodes/disposition-codes-model';
import { DispositionCodesService } from 'features/organisation/organisation/dispositioncodes/disposition-codes-service';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { Event, MESSAGE_EVENTS, SessionStore } from 'zailab.common';
import { IMInteractionCardService, InstantMessageBody, } from './im-interaction-card-service';

const logger = LogManager.getLogger('IMInteractionCard');

@autoinject
@customElement('z-im-interaction-card')
export class IMInteractionCard {
  
  @bindable public interaction: InteractionModel;
  @bindable public contactid: string;
  @bindable public contact: ContactModel;
  @bindable public unlinked: boolean;
  @bindable public conversations: ConversationModel[];
  @bindable public correlationid: string;

  public transcript: any[];
  public userData: any;
  public isUploading: boolean = false;
  public uploadImage: HTMLInputElement;
  public showDispositionCodeSelectButton: boolean = false;
  public disposition: string = null;
  public dispositionCode: DefaultDispositionCode = null;
  public showEndButton: boolean = false;
  public showSendButton: boolean = true;
  public sessionExpired: boolean = false;
  public customerTyping = false;
  public messageBody: HTMLTextAreaElement;
  public isLiveInteraction = false;
  public attachment: any;
  public emojiMenuVisible: any;
  public hasAdditionalData: boolean;
  public additionalDataVisible = false;
  public additionalData = [];
  public emojiPickerWrapper = 'text-input-' + uuidv4();
  public displayTransfer: boolean;
  public sendingMessage: boolean;

  private subscriptions: Subscription[] = [];
  private message: string = '';
  private sessionId: string = '';
  private oplogSubscription: {
    unsubscribe: () => void;
    on: (op: string, callback: (data: any) => void) => void;
  };
  private connectedInteractionsOplog: {
    unsubscribe: () => void;
    on: (op: string, callback: (data: any) => void) => void;
  };
  private timer: number;
  private isTyping: boolean;
  private emojiInitialised = false;

  constructor(
    private element: Element,
    private sessionStore: SessionStore,
    private eventAggregator: EventAggregator,
    private imInteractionCardService: IMInteractionCardService,
    private conversationService: ConversationService,
    private dispositionCodesService: DispositionCodesService,
    private contactService: ContactService,
    private observerLocator: ObserverLocator,
  ) { }

  public bind(): void {
    this.subscriptions.push(
      this.eventAggregator.subscribe(
        'select.disposition',
        (dispositionCode: DispositionCode) => {
          this.updateInteractionDisplay();
        }
      )
    );

    this.subscriptions.push(
      this.eventAggregator.subscribe(INTERACTION_ACTIONS.SELECT, (data) => {
        if (this.interaction.interactionId === data.interaction.interactionId) {
          this.updateInteractionDisplay();
        }
      })
    );

    this.observerLocator
      .getObserver(this.interaction, 'isLive')
      .subscribe((isLive) => {
        if (isLive) {
          this.initialiseEmoji();
        }
      });
  }

  public attached(): void {
    this.updateChatInteraction();

    if (this.interaction.isLive) {
      this.initialiseEmoji();
    }
  }

  private initialiseEmoji(): void {
    if (this.emojiInitialised) {
      return;
    }
    this.emojiInitialised = true;
    const emojiWrapper = document.querySelector('#' + this.emojiPickerWrapper);
    if (!emojiWrapper) {
      setTimeout(() => {
        this.emojiInitialised = false;
        this.initialiseEmoji();
      }, 50);
      return;
    }
    const emojiPicker: any = new Picker({
      data,
      categories: ['people', 'nature', 'foods', 'activity', 'places', 'objects', 'symbols', 'flags'],
      perLine: 12,
      previewPosition: 'none',
      skinTonePosition: 'search',
      theme: 'dark',
      onEmojiSelect: emoji => {
        if (emoji && emoji.native) {
          this.message += emoji.native;
          this.toggleEmojiMenu();
        }
      }
    });
    emojiWrapper.append(emojiPicker);
    window.addEventListener('keypress', this.handleKeypress);
  }

  public toggleEmojiMenu(): void {
    this.emojiMenuVisible = !this.emojiMenuVisible;

    let emEmojiPicker = document.querySelector('em-emoji-picker');
    if (this.emojiMenuVisible) {
      emEmojiPicker.classList.add('visible');
    } else {
      emEmojiPicker.classList.remove('visible');
    }
  }

  public hideEmojiIfVisible() {
    if (this.emojiMenuVisible) {
      this.toggleEmojiMenu();
    }
  }

  public detached(): void {
    this.subscriptions.forEach((subscription) => subscription.dispose());
    if (this.oplogSubscription) {
      this.oplogSubscription.unsubscribe();
    }
    if (this.connectedInteractionsOplog) {
      this.connectedInteractionsOplog.unsubscribe();
    }
    window.removeEventListener('keypress', this.handleKeypress);
  }

  private handleKeypress = (event: any) => {
    if ((event.keyCode == 10 || event.keyCode == 13) && event.ctrlKey) {
      this.sendMessage();
    } else if (
      event.srcElement &&
      event.srcElement.tagName === 'TEXTAREA'
    ) {
      this.startTyping();
    }
  };

  private notifyCardChange(): void {
    this.eventAggregator.publish(
      CONTACT_ACTIONS.DETECT_CHANGE,
      this.correlationid
    );
  }

  private updateTranscript(data: any): void {
    this.notifyCardChange();

    if (data && data.messages) {
      data.messages.forEach(message => {
        message.text = message.text.replace(/(?:\r\n|\r|\n)/g, '<br>');
      });
      this.transcript = data.messages;
      this.transcript.forEach((message) => {
        if (message.media && message.media.type && message.media.url) {
          if (
            this.isImage(message.media.type) &&
            !this.isPDF(message.media.type)
          ) {
            message.text = `<img style="max-width:100%; max-height:100%;" src="${message.media.url}" onerror="this.onerror=null;this.src='${imageIconContent}';this.style.width='100px';this.style.height='100px'" >`;
          } else if (this.isPDF(message.media.type)) {
            const htmlContent = `
              <div>
                <div class="font-medium">
                  <img src="${pdfImageContent}" />
                </div>
              </div>
            `;

            message.image = htmlContent;
          } else {
            const htmlContent = `
              <div if.bind="${!this.isImage(message.media.type)}">
                <div class="truncate" title="${message.media.title}">
                  ${message.media.title}
                </div>
                <div class="font-medium">
                  <b>${this.getFileTypeString(message.media.type)}</b>
                </div>
              </div>
            `;

            message.text = htmlContent;
          }
        }
      });
    }
    if (data) {
      this.sessionExpired = data.expired;
      this.showSendButton = !this.sessionExpired && this.showEndButton;
      this.isLiveInteraction = this.showEndButton;
      this.customerTyping = data.userTyping;
    }
    if (data && data.sessionId) {
      this.sessionId = data.sessionId;
      this.interaction.sessionId = data.sessionId;
      this.eventAggregator.publish('ADD:SESSIONID:TO:INTERACTION', {
        contactId: this.contactid,
        correlationId: this.correlationid,
        interactionId: this.interaction.interactionId,
        sessionId: data.sessionId,
      });
    }
    if (data && data.userData) {
      this.userData = data.userData;
    }
    setTimeout(() => {
      var transcriptElem = document.querySelector('#js-transcript');
      if (transcriptElem) {
        transcriptElem.scrollTop = transcriptElem.scrollHeight;
      }
    }, 500);
  }

  public async downloadFile(media: {
    title: string;
    url: string;
    type: string;
  }): Promise<void> {
    try {
      const handle = await this.showDynamicSaveFilePicker(
        media.type,
        media.title
      );
      const writable = await handle.createWritable();

      // Fetch the content from the URL
      const response = await fetch(media.url);
      if (!response.ok) {
        throw new Error('Network response was not ok' + response.statusText);
      }

      const content = media.type.startsWith('text/')
        ? await response.text()
        : await response.arrayBuffer();

      const blob = media.type.startsWith('text/')
        ? new Blob([content], { type: media.type })
        : new Blob([content], { type: media.type });

      // Write the blob to the file
      await writable.write(blob);
      await writable.close();
    } catch (error) {
      console.error('Error showing save file picker:', error);
    }
  }

  private async showDynamicSaveFilePicker(mimetype, title): Promise<any> {
    const fileExtensionMap = {
      'image/pdf': '.pdf',
      'application/pdf': '.pdf',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        '.xlsx',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        '.docx',
      'application/msword': '.doc',
      'text/plain': '.txt',
      'application/vnd.ms-excel': '.xls',
      'application/vnd.ms-powerpoint': '.ppt',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        '.pptx',
      'text/csv': '.csv',
      'image/png': '.png',
      'image/jpeg': '.jpg',
      'image/gif': '.gif',
      'image/bmp': '.bmp',
      'image/svg+xml': '.svg',
    };

    // Determine the file extension and description based on the mime type
    const fileExtension = fileExtensionMap[mimetype];
    if (!fileExtension) {
      throw new Error(`Unsupported mime type: ${mimetype}`);
    }

    const fileDescription = this.getFileTypeString(mimetype);

    // Open the save file picker dialog
    const fileHandle = await window.showSaveFilePicker({
      types: [
        {
          description: `File (${mimetype})`,
          accept: { [mimetype]: [fileExtension] },
        },
      ],
      suggestedName:
        title || fileDescription || `${title || 'untitled'}${fileExtension}`,
    });

    return fileHandle;
  }

  private updateChatInteraction(): void {
    if (this.interaction && this.interaction.interactionId) {
      this.imInteractionCardService
        .getTranscript(this.interaction.interactionId)
        .then((transcript) => {
          if (Object.keys(transcript).length > 0) {
            this.updateTranscript(transcript);
          }
        })
        .catch((e) => { });
      this.oplogSubscription =
        this.imInteractionCardService.onTranscriptChanged(
          this.interaction.interactionId
        );
      this.oplogSubscription.on('insert', (data) => {
        this.updateTranscript(data && data.transcript ? data.transcript : data);
        this.sendingMessage = false;
      });
      this.oplogSubscription.on('update', (data) => {
        this.updateTranscript(data && data.transcript ? data.transcript : data);
        this.sendingMessage = false;
      });
    }
  }

  public end(): void {
    this.showEndButton = false;
    const sessionId = this.sessionId;
    this.imInteractionCardService.endInteraction(sessionId);
    this.eventAggregator.publish('cc-interaction-ended', {
      interactionId: this.interaction.interactionId,
      contactId: this.contact.contactId,
      correlationId: this.contact.correlationId,
    });
  }

  private sendMessage(): void {
    this.hideEmojiIfVisible();

    if (this.message.length > 0 || this.attachment) {
      this.sendingMessage = true;
      const chatId = this.interaction.additionalData.chatId;
      if (chatId) {
        this.imInteractionCardService.sendInteracomIOMessage(
          chatId,
          this.message
        );
        this.message = '';
      } else {
        const sessionId = this.sessionId;
        const messageId = uuidv4();
        const message = new InstantMessageBody();
        message.sender = {
          number: '0027212860659', // ???
          memberId: this.sessionStore.get.user.memberId,
        };
        message.text = this.message.replace(/(?:\r\n|\r|\n)/g, '<br>');
        message.to = this.interaction.from;
        this.imInteractionCardService.sendChatMessage(
          sessionId,
          messageId,
          message,
          this.attachment
        );
        this.message = '';
        this.attachment = null;
      }
    }
  }

  public openFileSelector(): void {
    this.uploadImage.click();
  }

  public selectFile(event: Event): void {
    // @ts-ignore
    let files = event.target.files;

    if (!files || !files.length) {
      return;
    }
    this.isUploading = false;
    if (files && files.length > 0) {
      const file = files[0];
      const reader = new FileReader();

      const allowedTypes = ['text/csv', 'image/jpeg', 'image/png', 'image/gif', 'image/pdf', 'application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

      if (allowedTypes.indexOf(file.type) === -1) {
        const message =
          'Invalid file type. Please upload a CSV, JPEG, PNG, GIF, PDF, DOC, or DOCX file.';
        this.eventAggregator.publish(MESSAGE_EVENTS.WARNING, message);
        return;
      }
      this.isUploading = true;

      reader.onload = (event: any) => {
        this.attachment = {
          content: file,
          displayContent: event.target.result,
          name: file.name,
          type: file.type,
        };
        this.isUploading = false;
        this.sendMessage();
      };
      reader.readAsDataURL(file);
      this.uploadImage.value = '';
    }
  }

  private updateInteractionDisplay(): void {
    this.updateChatInteraction();
    // For when we connect DISPOSITION CODES
    this.showDispositionCodeSelectButton = false;

    this.conversationService
      .retrieveInteraction(this.interaction.interactionId)
      .then((interaction) => {
        const metaData = interaction.metaData as any;
        this.disposition = metaData.disposition;

        if (metaData) {
          this.interaction.metaData = metaData;
        }

        if (interaction.agent) {
          this.interaction.agent = interaction.agent;
        }

        this.interaction.additionalData = interaction.additionalData;
        if (interaction.additionalData) {
          this.additionalData = [];
          let promises = [];

          // @ts-ignore
          let additionalData = Object.entries(interaction.additionalData);
          additionalData.forEach((data) =>
            promises.push(
              new Promise(async (resolve) => {
                let prop = data[0];
                let value = data[1];

                if (prop.startsWith('vb_')) {
                  resolve(null);
                }
                if (prop === 'Req MSISDN') {
                  this.interaction.msisdn = value;
                }

                let item: any = { name: prop, value };

                if (prop.startsWith('Link_')) {
                  item.name = item.name.replace('Link_', '');
                  item.isLink = true;
                } else if (prop.startsWith('IFrame_')) {
                  item.name = item.name.replace('IFrame_', '');
                  item.isIFrameLink = true;
                } else if (prop.startsWith('Player_')) {
                  item.url = value;
                  item.isPlayerLink = true;
                } else if (prop.startsWith('ZailabRecording_')) {
                  const value = await this.retrieveInteractionRecording(item.value);
                  item.url = value;
                  item.isZailabRecording = true;
                } else if (prop.startsWith('Display_')) {
                  const parts = item.name.split('_');
                  item.name = parts[2];
                  item.requestProperty = parts[1];
                  const value = await this.getAdditionalDataDisplay(item);
                  item.value = value;
                  item.isDisplay = true;
                } else if (prop.startsWith('Button_')) {
                  const parts = item.name.split('_');
                  item.name = parts[2];
                  item.requestMethod = parts[1];
                  item.isButton = true;
                }
                resolve(item);
              })
            )
          );

          Promise.all(promises).then((results) => {
            this.additionalData = results.filter((item) => !!item);
            this.hasAdditionalData = this.additionalData.length > 0;
          });
        }

        if (metaData.serviceId && metaData.direction) {
          let organisationId =
            this.sessionStore.get.organisation.organisationId;
          let serviceId = metaData.serviceId;
          let direction =
            metaData.direction === 'INBOUND' ? 'Inbound' : 'Outbound';
          let channel: string;
          if (this.isIM) {
            channel = 'Instant Message';
          } else {
            channel = 'Chat';
          }

          this.dispositionCodesService
            .retrieveDispositionListByServiceChannelAndDirection(
              organisationId,
              serviceId,
              channel,
              direction,
              metaData.tiered
            )
            .then((dispositionList: DispositionModel) => {
              this.showDispositionCodeSelectButton =
                dispositionList &&
                dispositionList.dispositionCodes &&
                dispositionList.dispositionCodes.length > 0;
              if (this.showDispositionCodeSelectButton) {
                this.eventAggregator.publish(
                  'select-disposition-code:enabled' +
                  this.interaction.conversationId
                );
              }
            })
            .catch(() => { });
        }
      });
  }

  public triggerAdditionalDataButton(data: any): void {
    this.contactService.triggerRequest(data.value, data.requestMethod);
  }

  public async getAdditionalDataDisplay(data: any): Promise<string> {
    return await this.contactService.fetchFromUrl(
      data.value,
      data.requestProperty
    );
  }

  public async retrieveInteractionRecording(interactionId: string): Promise<string> {
    const response = await this.conversationService.retrieveRecording(interactionId);
    if (response) {
      return response.url;
    }
  }

  @computedFrom('interaction.channel')
  public get isIM(): boolean {
    return (
      this.interaction &&
      this.interaction.channel.toLowerCase() === 'instant_message'
    );
  }

  public showAdditionalData(): void {
    this.additionalDataVisible = true;
  }

  public startTyping(): void {
    window.clearTimeout(this.timer);
    if (!this.isTyping) {
      this.contactService.notifyTypingStart(this.sessionId);
    }
    this.isTyping = true;
    this.startStopTypingTimer();
  }

  private startStopTypingTimer(): void {
    window.clearTimeout(this.timer);
    this.timer = window.setTimeout(() => {
      this.isTyping = false;
      this.contactService.notifyTypingStop(this.sessionId);
    }, 1000);
  }

  public handleKeyDown(event: any): boolean {
    if (this.messageBody) {
      this.messageBody.style.height = this.messageBody.scrollHeight + 'px';
    }
    return this.handleEnter(event);
  }

  private handleEnter(evt: KeyboardEvent): boolean {
    if (evt.keyCode === 13 && evt.shiftKey) {
      if (evt.type === 'keydown') {
        this.message += '\n';
      }
      evt.preventDefault();
      return false;
    } else if (evt.keyCode === 13) {
      if (evt.type === 'keydown') {
        this.sendMessage();
      }
      evt.preventDefault();
      return false;
    }
    return true;
  }

  public selectDispositionCode(event: Event): void {
    event.stopPropagation();
    this.eventAggregator.publish('member.select.disposition.code', {
      interactionId: this.interaction.interactionId,
      correlationId: this.correlationid,
      conversationId: this.interaction.conversationId,
    });
  }

  public openLink(url: string) {
    this.eventAggregator.publish('interaction-link-open', {
      firstName: this.contact.firstName,
      surname: this.contact.surname,
      remote: this.interaction.from,
      url: url,
      interactionId: this.interaction.interactionId,
    });
    this.eventAggregator.publish('dock-cards');
  }

  public formatToTime(timestamp: number): any {
    if (timestamp) {
      return moment(timestamp).format('HH:mm');
    }
  }

  @computedFrom('dispositionCode')
  public get dispositionColor(): string {
    if (!this.dispositionCode) {
      return '';
    }
    return this.dispositionCode.isNegative
      ? 'var(--unsuccessful)'
      : this.dispositionCode.isPositive
        ? 'var(--successful)'
        : '';
  }

  @computedFrom('dispositionCode')
  public get dispositionTitle(): string {
    if (!this.dispositionCode) {
      return '';
    }
    return `Outcome: ${this.dispositionCode.conversationOutcome}\nReason: ${this.dispositionCode.interactionOutcomeReason}`;
  }

  public getFileTypeString(mimetype): string {
    if (!mimetype) {
      return 'Unknown File';
    }

    // Split the mimetype into main type and subtype
    const parts = mimetype.split('/');

    // Handle images
    if (parts[0] === 'image') {
      return `${parts[1].toUpperCase()} Image`;
    }

    // Handle other file types with extension-based mapping
    const extensionMap = {
      'image/pdf': 'PDF File',
      'application/pdf': 'PDF File',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        'XLSX File',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        'DOCX File',
      'application/msword': 'DOC File',
      'text/plain': 'TXT File',
      'application/vnd.ms-excel': 'XLS File',
      'application/vnd.ms-powerpoint': 'PPT File',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        'PPTX File',
      'text/csv': 'CSV File',
      'image/png': 'PNG Image',
      'image/jpeg': 'JPEG Image',
      'image/gif': 'GIF Image',
      'image/bmp': 'BMP Image',
      'image/svg+xml': 'SVG Image',
    };

    if (extensionMap[mimetype]) {
      return extensionMap[mimetype];
    }

    // Handle unknown file types with generic category based on main type
    switch (parts[0]) {
      case 'application':
        return 'Document';
      case 'audio':
        return 'Audio File';
      case 'video':
        return 'Video File';
      case 'text':
        return 'Text File';
      default:
        return 'Unknown File';
    }
  }

  public isImage(type: string): boolean {
    return type.indexOf('image') >= 0 && type.indexOf('pdf') === -1;
  }

  public isPDF(type: string): boolean {
    return type.indexOf('pdf') >= 0;
  }

  public toggleTransfer(): void {
    this.displayTransfer = !this.displayTransfer;
  }

  public hideTransfer(): void {
    this.displayTransfer = false;
  }
}
