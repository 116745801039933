import { DispositionModel } from './../organisation/dispositioncodes/disposition-codes-model';
import { DispositionCodesService } from './../organisation/dispositioncodes/disposition-codes-service';
import { SessionStore } from './../../../_common/stores/session-store';
import { Subscription } from 'aurelia-event-aggregator';
import {
  LogManager,
  Container,
  observable,
  computedFrom,
} from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';

import { ApplicationProperties, MESSAGE_EVENTS, ZaiForm } from 'zailab.common';
import { ZIOplog } from '../../../../typings/zai/zai.common';
import { BaseModel } from 'zailab.abstract';
import { MemberModel } from './member-model';
import { RecordingModel } from './interactioncards/call/recording-model';
import { WaypointModel } from './waypoint-model';
import { TranscriptModel } from './transcript-model';
import { CallTranscriptModel } from './call-transcript-model';
import { ContactService } from '../contact/contact-service';
import { ConversationService } from './conversation-service';
import { TelephonyService } from '../../telephony/telephony-service';
import { FeatureFlagService } from '../../featureflags/feature-flag-service';
import { INTERACTION_ACTIONS } from './interaction/interaction-actions';

// @ts-ignore
import moment from 'moment-timezone';
//@ts-ignore
import toastr from 'toastr';
import { CallSentimentModel } from './call-sentiment-model';
import { DetectedPhraseModel } from './detected-phrase-model';
import { ConversationModel } from './conversation-model';

const logger = LogManager.getLogger('InteractionBaseModel');

export class InteractionModel extends BaseModel {
  private contactService: ContactService =
    Container.instance.get(ContactService);
  private conversationService: ConversationService =
    Container.instance.get(ConversationService);
  private telephonyService: TelephonyService =
    Container.instance.get(TelephonyService);
  private eventAggregator: EventAggregator =
    Container.instance.get(EventAggregator);
  private applicationProperties: ApplicationProperties = Container.instance.get(
    ApplicationProperties
  );
  private featureFlagService: FeatureFlagService =
    Container.instance.get(FeatureFlagService);
  private sessionStore: SessionStore = Container.instance.get(SessionStore);
  private dispositionCodesService: DispositionCodesService =
    Container.instance.get(DispositionCodesService);

  public channel: string = null;
  public routerId: string = null;

  public id: string = null;
  public _id: string = null;
  public content: Object = null;
  public agent: any = null;
  public metaData: {
    contactId: string;
    diallerType: string;
    messageAutoAnswer: string;
  } = null;
  public conversationName: string = null;
  public dispositionCode: any = null;
  public direction: string = null;
  public agentRatingSurveyPercentage: string = null;
  public interactionId: string = null;
  public sessionId: string = null;
  public contactId: string = null;
  public externalReference: string = null;
  public callOutcome: string = null;
  public duration: number = null;
  public from: string = null;
  public email: string = null;
  public recipientNumber: string = null;
  public flow: string = null;
  public onCall: boolean = false;
  public organisationId: string = null;
  public conversationId: string = null;
  public taskName: string = null;
  public timestamp: number = null;
  public name: string = null;
  public firstName: string = null;
  public member: any = null;
  public surname: string = null;
  public subject: string = null;
  public to: string = null;
  public tos: Array<string> = null;
  public ccs: Array<string> = null;
  public bccs: Array<string> = null;
  public toNumber: string = null;
  public type: string = null;
  public originalMessage: string = null;
  public message: string = null;
  public memberId: string = null;
  public html: string = null;
  public isActive: boolean = false;
  public isTask = false;
  public userId: string = null;
  public emailId: string = null;
  public noteId: string = null;
  public reminderId: string = null;
  public text: string = null;
  public recording: RecordingModel = new RecordingModel();
  public payload: Object = null;
  public journey: WaypointModel[] = [];
  @observable public isLive: boolean = null;
  public isOnWrapup: boolean = false;
  public note: string = null;
  public flowName: string = null;
  public workType: string = null;
  public attachmentDetails: Object[] = [];
  public recordingId: string = null;
  public src: string = null;
  public dst: string = null;
  public isSelected: boolean = false;
  public serviceId: string = null;
  public disposition: string = null;
  public additionalData: Array<any> = null;
  public mappedAdditionalData: Array<any> = null;
  public additionalProperties: any = null;
  public taskEnded: Boolean = null;
  public msisdn: Boolean = null;
  
  public formData: Array<{
    displayText: string,
    variableName: string,
    inputType: string,
    value: any
  }> = null;
  @observable public inputCaptureFormData: {
    [key: string]: string | boolean;
    valid: boolean;
  };
  public form: any[][];
  private inputCaptureTimeoutId: ReturnType<typeof setTimeout>;

  public campaignExternalReference: string = null;
  public campaignId: string = null;
  public campaignName: string = null;
  public prospectId: string = null;
  public prospectListExternalReference: string = null;
  public prospectListId: string = null;
  public prospectListName: string = null;

  public transcript: TranscriptModel = null;
  private taskId: string = null;
  public correlationId: string = null;
  public canAccept: boolean = false;

  public reminderType: string = null;
  public dueTimestamp: string = null;
  private radix: number = 10;
  public membersOnWrapUp: any = null;
  public isServed: boolean = false;
  public resumeLinking: boolean = false;
  public wrapUp: boolean = false;
  public wrapUpChannelIds: Array<string> = [];
  public isCallBack: boolean = false;
  public recentActivity: boolean = false;

  public isRecording: boolean = null;
  public isResuming: boolean = null;
  public recordingActive: boolean = null;
  public recordingPaused: boolean = null;
  public recordingIsEnabled: boolean = false;
  public enableLoader: boolean = null;
  public showConversationCard: boolean = true;
  public numberOfContacts: number = null;
  public isDiallerCall: boolean = false;
  public wasAnswered: boolean = false;
  public screenPopUrl: string;
  public useEmbeddedIFrame: string = null;
  public callSentiment: CallSentimentModel;
  public callTranscript: CallTranscriptModel;
  public detectedPhrases: DetectedPhraseModel[];
  public consultInteractions: string[];
  public linkedConsultingInteractionId: string;
  public isConsulting: boolean;
  public pauseResumeRecordingDisabled: boolean;

  public conference: boolean = null;
  public conferenceThresholdBreached: boolean = null;
  public workTypeTransferEnabled: boolean = null;

  private oplogSubscription: Subscription;
  private interactionSubscription: Subscription;
  private connectedInteractionSubscription: Subscription;
  public isLinkedToConversation: boolean = false;
  public isLinkingToConversation: boolean = false;
  public isLinkingToContact: boolean = false;

  public showDispositionCodeSelectButton: boolean;
  public showDispositionOrOutcome: boolean = false;

  private isMock = false;
  private isMockConsulting = false;

  public loaders = {
    journey: false,
  };

  constructor(interaction?: any) {
    super();
    super.mapProperties(interaction);
    this.mapObjects(interaction);
    this.checkFeatureFlags();
    this.subscribeToChanges();
  }

  public mapObjects(interaction?: any): void {
    if (interaction) {
      if (interaction.interactionId) {
        this.interactionId = interaction.interactionId;
        if (this.isMock) {
          // this is used for local testing to mock live interactions
          this.isLive = true;
        }
        if (this.isMockConsulting) {
          interaction.isConsulting = true;
        }
        this.subscribeToChanges();
      }
      if (
        interaction.formData && (
          !this.formData ||
          this.formData && !this.form
        )
      ) {
        this.formData = interaction.formData;
        this.generateInputCaptureForm();
      }
      if (interaction.recordingActive) {
        this.recordingIsEnabled = true;
      }
      if (interaction.channel) {
        this.channel = interaction.channel.toLowerCase();
      }
      if (interaction.taskId) {
        this.interactionId = interaction.taskId;
      }
      if (interaction.content) {
        super.mapProperties(interaction.content);
      }
      if (interaction.metaData) {
        this.metaData = interaction.metaData;
        super.mapProperties(interaction.metaData);
        if (interaction.metaData.recordingActive) {
          this.recordingIsEnabled = true;
        }
        if (interaction.metaData.channel) {
          this.channel = interaction.metaData.channel.toLowerCase();
        }
        if (interaction.metaData.serviceId) {
          this.serviceId = interaction.metaData.serviceId;
        }
        if (interaction.metaData.disposition) {
          this.disposition =
            interaction.metaData.disposition || this.disposition;
        }
        if (
          typeof interaction.metaData.showConversationCard !== 'undefined' &&
          interaction.metaData.showConversationCard !== null
        ) {
          this.showConversationCard = interaction.metaData.showConversationCard;
        }

        if (
          typeof interaction.metaData.isDiallerCall !== 'undefined' &&
          interaction.metaData.isDiallerCall !== null
        ) {
          this.isDiallerCall = interaction.metaData.isDiallerCall;
        }

        if (
          typeof interaction.metaData.wasAnswered !== 'undefined' &&
          interaction.metaData.wasAnswered !== null
        ) {
          this.wasAnswered = interaction.metaData.wasAnswered;
        }

        if (interaction.metaData.conversationId) {
          this.conversationId = interaction.metaData.conversationId;
        }

        if (interaction.metaData.contactId) {
          this.contactId = interaction.metaData.contactId;
        }
        if (interaction.metaData.screenPopUrl) {
          this.screenPopUrl = interaction.metaData.screenPopUrl;
        }

        if (
          interaction.metaData.useEmbeddedIFrame !== null &&
          typeof interaction.metaData.useEmbeddedIFrame !== 'undefined'
        ) {
          try {
            this.useEmbeddedIFrame = JSON.parse(
              interaction.metaData.useEmbeddedIFrame
            );
          } catch (e) {
            this.useEmbeddedIFrame = interaction.metaData.useEmbeddedIFrame;
          }
        }
      }

      if (interaction.additionalProperties) {
        super.mapProperties(interaction.additionalProperties);
      }

      if (!this.originalMessage && !interaction.ignoreImageStripping) {
        this.setMessage(interaction);
      }

      if (
        interaction.additionalData &&
        Object.keys(interaction.additionalData).length > 0
      ) {
        let promises = [];

        // @ts-ignore
        let additionalData = Object.entries(interaction.additionalData);
        additionalData.forEach((data) =>
          promises.push(
            new Promise(async (resolve) => {
              let prop = data[0];
              let value = data[1];

              if (prop.startsWith('vb_')) {
                resolve(null);
              }
              if (prop === 'Req MSISDN') {
                this.msisdn = value;
              }

              let item: any = { name: prop, value };

              if (prop.startsWith('Link_')) {
                item.name = item.name.replace('Link_', '');
                item.isLink = true;
              } else if (prop.startsWith('IFrame_')) {
                item.name = item.name.replace('IFrame_', '');
                item.isIFrameLink = true;
              } else if (prop.startsWith('Player_')) {
                item.url = value;
                item.isPlayerLink = true;
              } else if (prop.startsWith('ZailabRecording_')) {
                const value = await this.retrieveInteractionRecording(item.value);
                item.url = value;
                item.isZailabRecording = true;
              } else if (prop.startsWith('Display_')) {
                const parts = item.name.split('_');
                item.name = parts[2];
                item.requestProperty = parts[1];
                const value = await this.getAdditionalDataDisplay(item);
                item.value = value;
                item.isDisplay = true;
              } else if (prop.startsWith('Button_')) {
                const parts = item.name.split('_');
                item.name = parts[2];
                item.requestMethod = parts[1];
                item.isButton = true;
              }
              resolve(item);
            })
          )
        );

        Promise.all(promises).then((results) => {
          this.mappedAdditionalData = results.filter((item) => !!item);
        });
      }
    }
  }

  private generateInputCaptureForm(): void {
    if (!Array.isArray(this.formData)) {
      return;
    }
    let form = new ZaiForm();

    this.formData.forEach(item => {
      if (item.inputType === 'TEXTBOX') {
        form = form.newRow()
          .newField()
          .asTextInput()
          .withTitle(item.displayText, '180px')
          .withIdentifier(item.variableName)
          .withValue(item.value || '')
          .withValidation([{ validationType: ZaiForm.VALIDATION_TYPES.MAX_CHARACTER_LENGTH, value: 50 }])
          .insertField()
      } else if (item.inputType === 'TEXTAREA') {
        form = form.newRow()
          .newField()
          .asTextArea()
          .withTitle(item.displayText, '180px')
          .withIdentifier(item.variableName)
          .withValue(item.value || '')
          .withValidation([{ validationType: ZaiForm.VALIDATION_TYPES.MAX_CHARACTER_LENGTH, value: 300 }])
          .insertField()
      } else if (item.inputType === 'CHECKBOX') {
        form = form.newRow()
          .newField()
          .asCheckbox()
          .withTitle(item.displayText, '180px')
          .checkboxOnTheRight()
          .withIdentifier(item.variableName)
          .withValue(item.value === true || item.value === "true" ? true : false)
          .insertField()
      }
    })

    form.finaliseForm((_form) => {
      this.form = _form;
    });
  }

  public inputCaptureFormDataChanged(data: {
    [key: string]: string | boolean;
    valid: boolean;
  }): void {
    const { valid, ...newPayload } = data;
    if (!valid) {
      return;
    }
    this.formData = this.formData.map((item) => {
      if (newPayload.hasOwnProperty(item.variableName)) {
        return {
          ...item,
          value: newPayload[item.variableName],
        };
      }
      return item;
    });
    if (this.inputCaptureTimeoutId) {
      clearTimeout(this.inputCaptureTimeoutId);
    }

    this.inputCaptureTimeoutId = setTimeout(() => {
      this.conversationService
        .saveInputCapture(this.interactionId, this.formData)
        .catch(error => logger.error(' > Failed to save input capture due to', error));
    }, 2000);
  }

  private setMessage(interaction: {
    content?: { message: string };
    message: string;
    html: string;
    text: string;
  }): void {
    let content: string;
    if (interaction.message) {
      content = interaction.message;
    } else if (interaction.html) {
      content = interaction.html;
    } else if (interaction.text) {
      content = interaction.text;
    } else if (interaction.content && interaction.content.message) {
      content = interaction.content.message;
    }
    if (!content) {
      return;
    }
    this.originalMessage = content;
    this.message = this.stripImages(content);
  }

  private stripImages(content: string): string {
    return content.replace(/<img [^>]*src="[^"]*"[^>]*>/gm, '');
  }

  public implementImageFailures(): void {
    let el = document.querySelector('#js-email-content');
    if (!el) {
      setTimeout(() => this.implementImageFailures(), 100);
      return;
    }
    let images = el.querySelectorAll('img');

    images.forEach((image) => {
      image.onerror = function (): void {
        this.onerror = null;
        this.width = 60;
        this.height = 57;
        this.title = 'Image not found.';
        this.src = '_assets/img/icon_broken-image.png';
      };
    });
  }

  public async getAdditionalDataDisplay(data: any): Promise<string> {
    return await this.contactService.fetchFromUrl(
      data.value,
      data.requestProperty
    );
  }

  public async retrieveInteractionRecording(interactionId: string): Promise<string> {
    const response = await this.conversationService.retrieveRecording(interactionId);
    if (response) {
      return response.url;
    }
  }

  public mapRecording(interaction: InteractionModel): void {
    this.recordingActive = interaction.recordingActive;
    this.recordingPaused = interaction.recordingPaused;
    if (interaction.recordingActive) {
      this.recordingIsEnabled = true;
    }
  }

  private checkFeatureFlags(): void {
    this.featureFlagService
      .isEnabled('disablePauseResumeRecording')
      .then((pauseResumeRecordingDisabled) => {
        this.pauseResumeRecordingDisabled = pauseResumeRecordingDisabled;
      });
  }

  public async linkToContact(contactId: string): Promise<void> {
    if (
      (!contactId && !this.isLinkingToContact) ||
      this.channel === 'callback'
    ) {
      return;
    }
    this.contactId = contactId;
    logger.debug(this.getCurrentFormattedTime() + ' - CC | linkToContact ', {
      contactId: this.contactId,
      isLinkedToConversation: this.isLinkedToConversation,
    });
    this.isLinkingToContact = true;
    await this.contactService.linkInteractionToContact(
      contactId,
      this.interactionId
    );
  }

  public linkToConversation(conversationId: string): void {
    logger.debug(
      this.getCurrentFormattedTime() + ' - CC | linkToConversation ',
      {
        contactId: this.contactId,
        isLinkedToConversation: this.isLinkedToConversation,
        conversationId,
      }
    );
    if (!this.contactId || !conversationId || this.isLinkedToConversation) {
      return;
    }
    logger.debug(
      this.getCurrentFormattedTime() + ' - CC | linkToConversation | linking '
    );

    this.conversationId = conversationId;
    if (this.channel === 'campaign') {
      this.conversationService.linkConversationToProspect(
        conversationId,
        this.prospectListId,
        this.prospectId
      );
      this.conversationService.linkTaskToConversation(
        conversationId,
        this.interactionId,
        this.campaignName,
        this.prospectListName,
        this.contactId
      );
    } else if (this.channel === 'callback') {
      this.conversationService.linkTaskToConversation(
        conversationId,
        this.interactionId,
        this.campaignName,
        this.prospectListName,
        this.contactId,
        'CALLBACK'
      );
    } else {
      this.eventAggregator.publish(
        'conversation-oplog-subscribe',
        conversationId
      );
      this.conversationService.linkInteractionToConversation(
        this.interactionId,
        conversationId,
        this.contactId
      );
    }
    this.triggerInteractionLinkedToConversation();
    this.isLinkedToConversation = true;
  }

  private triggerInteractionLinkedToConversation(): void {
    this.eventAggregator.publish(
      INTERACTION_ACTIONS.INTERACTION_LINKED_TO_CONVERSATION,
      { interactionId: this.interactionId, contactId: this.contactId }
    );
  }

  @computedFrom('timestamp')
  get relativeTime(): string {
    return moment(this.timestamp ? parseInt(this.timestamp) : Date.now())._d;
  }

  @computedFrom('dueTimestamp')
  get dueDate(): string {
    return moment(parseInt(this.dueTimestamp, this.radix)).format('DD/MM/YYYY');
  }

  @computedFrom('dueTimestamp')
  get dueTime(): string {
    return moment(parseInt(this.dueTimestamp, this.radix)).format('HH:mm');
  }

  public updateRecordingActive(recordingId: any): void {
    if (recordingId) {
      this.recordingActive = true;
    }
  }

  public mapInteraction(interaction: any, ignoreDispositions?: boolean): void {
    if (!interaction) {
      return;
    }
    if ('isConsulting' in interaction) {
      if (this.isConsulting && !interaction.isConsulting) {
        localStorage.removeItem('zai-consult');
      }
      if (this.isMockConsulting) {
        this.isConsulting = true;
      } else {
        if (
          interaction.hasOwnProperty('isConsulting') &&
          typeof interaction.isConsulting === 'boolean'
        ) {
          this.isConsulting = interaction.isConsulting;
        }
      }
    }

    if (!interaction._id && !interaction.metaData) {
      return;
    }
    super.mapProperties(interaction);
    if (interaction.channel === 'chat') {
      interaction.type = 'CHAT';
    }
    if (interaction.channel === 'instant_message') {
      interaction.type = 'IM';
    }
    this.mapObjects(interaction);
    this.agent = interaction.agent || interaction.member || this.agent;
    this.member = interaction.member || this.member;
    this.type = interaction.type || interaction.waypointType || this.type;
    this.timestamp = interaction.timestamp
      ? interaction.timestamp
      : this.timestamp;
    if (interaction.waypoints) {
      this.journey = interaction.waypoints.map(
        (waypoint) => new WaypointModel(waypoint)
      );
    }
    if (interaction.firstName || interaction.surname) {
      this.member = {
        firstName: interaction.firstName,
        surname: interaction.surname,
      };
    }
    if (interaction.direction) {
      this.direction = interaction.direction.toLowerCase();
    }
    if (interaction.metaData) {
      this.metaData = interaction.metaData;
      this.recordingActive = interaction.metaData.recordingActive;
      this.recordingIsEnabled = interaction.metaData.recordingActive;
      this.recordingId = interaction.metaData.recordingId;
      this.recordingPaused = interaction.metaData.recordingPaused;
    }
    if (
      interaction.journey &&
      interaction.journey.waypoints &&
      interaction.journey.waypoints.length > 0
    ) {
      this.journey = interaction.journey.waypoints.map(
        (waypoint) => new WaypointModel(waypoint)
      );
    }

    if (interaction.dispositionCode) {
      this.dispositionCode = interaction.dispositionCode;
    }
    if (interaction.metaData && !ignoreDispositions) {
      this.disposition = interaction.metaData.disposition || this.disposition;
      this.dispositionCode = interaction.metaData.dispositionCode;
      if (this.disposition) {
        this.showDispositionOrOutcome = true;
      }

      const metaData = interaction.metaData as any;
      if (metaData.serviceId && metaData.direction) {
        let organisationId = this.sessionStore.get.organisation.organisationId;
        let serviceId = metaData.serviceId;
        let direction =
          metaData.direction === 'INBOUND' ? 'Inbound' : 'Outbound';
        let channel;
        switch (interaction.metaData.channel) {
          case 'VOICE':
            channel = `${direction} Call`;
            break;
          case 'EMAIL':
            channel = 'Email';
            break;
          case 'CHAT':
            channel = 'Chat';
            break;
          case 'INSTANT_MESSAGE':
          case 'instant_message':
            channel = 'Instant Message';
            break;
          default:
            channel = interaction.metaData.channel;
            break;
        }

        this.dispositionCodesService
          .retrieveDispositionListByServiceChannelAndDirection(
            organisationId,
            serviceId,
            channel,
            direction,
            metaData.tiered
          )
          .then((dispositionList: DispositionModel) => {
            this.showDispositionCodeSelectButton =
              dispositionList &&
              dispositionList.dispositionCodes &&
              dispositionList.dispositionCodes.length > 0;
            if (this.showDispositionCodeSelectButton) {
              setTimeout(() => {
                this.eventAggregator.publish(
                  'select-disposition-code:enabled' + this.conversationId,
                  this.showDispositionCodeSelectButton
                );
              }, 400);
            }
          });
      }
    }
  }

  public select(): void {
    this.isSelected = true;
    this.isActive = true;
  }

  public deselect(): void {
    this.isSelected = false;
    this.isActive = false;
  }

  private retrievingRecording = false;
  public retrieveRecordingUrl(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (
        this.isCallChannel() &&
        this.type !== 'callback' &&
        !this.recording.recordingURL &&
        !this.retrievingRecording &&
        (!this.recording || (this.recording && !this.recording.error))
      ) {
        this.retrievingRecording = true;
        this.recording.loader = true;
        this.conversationService
          .retrieveRecording(this.interactionId)
          .then((recordingData) => {
            if (recordingData) {
              this.conversationService
                .checkRecording(recordingData.url)
                .then(() => {
                  this.recording.loader = false;
                  this.recording.recordingURL = recordingData.url;
                  resolve();
                })
                .catch((err) => {
                  this.recording.loader = false;
                  this.recording.error = true;
                  reject(err);
                });
            }
          });
      } else {
        reject(new Error('Unable to retrieve recording'));
      }
    });
  }

  public togglePauseResume(): void {
    if (this.recordingActive && this.recordingPaused === false) {
      this.pauseRecording();
    } else if (this.recordingActive && this.recordingPaused === true) {
      this.resumeRecording();
    }
  }

  public pauseRecording(): void {
    this.enableLoader = true;
    this.telephonyService
      .pauseRecording(this.interactionId)
      .then(() => {
        this.isRecording = false;
        this.recordingPaused = true;
        this.enableLoader = false;
      })
      .catch((error: { response: string }) => {
        this.displayToastr(MESSAGE_EVENTS.ERROR, error.response);
        this.isRecording = true;
        this.enableLoader = true;
      });
  }

  public resumeRecording(): void {
    this.enableLoader = true;
    this.telephonyService
      .resumeRecording(this.interactionId)
      .then(() => {
        this.isRecording = true;
        this.recordingPaused = false;
        this.enableLoader = false;
      })
      .catch((error: { response: string }) => {
        this.displayToastr(MESSAGE_EVENTS.ERROR, error.response);
        this.isResuming = false;
        this.isRecording = false;
        this.enableLoader = true;
      });
  }

  private displayToastr(eventName: string, message: string): void {
    let options = Object.assign({}, toastr.options);
    toastr.options = {
      positionClass: 'toast-top-center',
      progressBar: true,
    };
    this.eventAggregator.publish(eventName, message);
    toastr.options = options;
  }

  public isCallChannel(): boolean {
    let callChannels = [
      'call',
      'voice',
      'inbound call',
      'outbound call',
      'flow call',
      'inbound flow call',
      'outbound flow call',
      'internal call',
    ];
    return callChannels.includes(this.channel);
  }

  public subscribeToChanges(): void {
    const interactionId = this.interactionId;
    if (!interactionId || this.interactionSubscription) {
      return;
    }

    this.interactionSubscription = this.eventAggregator.subscribe(
      'connected-interaction-' + interactionId,
      (interaction: any) => {
        if (!this.interactionId) {
          return (
            this.interactionSubscription &&
            this.interactionSubscription.dispose()
          );
        }
        if (interaction.interactionId !== interactionId) {
          return;
        }
        if (this.isMock) {
          return;
        }

        this.isOnWrapup = interaction.state === 'WRAP_UP';
        if (this.isOnWrapup) {
          this.isLive = false;
        } else if (interaction.state === 'CONNECTED') {
          this.isLive = true;
        }
        if (!this.isOnWrapup && !this.isLive) {
          setTimeout(() => {
            this.interactionSubscription &&
              this.interactionSubscription.dispose();
          }, 5000);
        }
      }
    );
    this.connectedInteractionSubscription = this.eventAggregator.subscribe(
      'connection-interaction-updated',
      (data) => {
        if (!this) {
          return (
            this.connectedInteractionSubscription &&
            this.connectedInteractionSubscription.dispose()
          );
        }
        if (this.isLive || this.isOnWrapup) {
          let isLiveInteraction =
            data &&
            data.interactions &&
            data.interactions.find(
              (interaction) => interaction.interactionId === this.interactionId
            );

          if (this.isMock) {
            return;
          }

          if (!isLiveInteraction) {
            this.isOnWrapup = false;
            this.isLive = false;
            this.eventAggregator.publish('cc-interaction-ended', {
              interactionId: this.interactionId,
              contactId: this.contactId,
              correlationId: this.correlationId,
            });
            setTimeout(
              () =>
                this.connectedInteractionSubscription &&
                this.connectedInteractionSubscription.dispose(),
              5000
            );
          }
        }
      }
    );
    this.eventAggregator.publish(
      'request-connected-interactions',
      this.interactionId
    );

    if (!this.interactionId) {
      return;
    }
    this.oplogSubscription && this.oplogSubscription.dispose();
    this.oplogSubscription = this.eventAggregator.subscribe(
      'interaction-oplog-update:' + this.interactionId,
      (data: InteractionModel) => {
        if (!this.interactionId) {
          return;
        }
        this.mapInteraction(data);
      }
    );
  }

  public unsubscribeFromEvents(): void {
    this.oplogSubscription && this.oplogSubscription.dispose();
    this.interactionSubscription && this.interactionSubscription.dispose();
    this.connectedInteractionSubscription &&
      this.connectedInteractionSubscription.dispose();
    this.eventAggregator.publish(
      'interaction-oplog-unsubscribe',
      this.interactionId
    );

    delete this.oplogSubscription;
    delete this.interactionSubscription;
    delete this.connectedInteractionSubscription;
  }

  @computedFrom('transcript')
  get prettyTranscript(): any {
    if (this.transcript && this.transcript.messages) {
      let transcript = [];
      this.transcript.messages.forEach((message) => {
        message.parts.forEach((part) => {
          let readableDate = moment(message.sentAt).format(
            'DD-MM-YYYY HH:mm:ss a'
          );
          if (message.sender.userId === 'true') {
            // @ts-ignore
            message.sender.id = 'Agent';
          }
          // @ts-ignore
          transcript.push({
            sender: message.sender.id,
            sentAt: readableDate,
            body: part.body,
          });
        });
      });
      return transcript;
    }
  }

  @computedFrom('type')
  get taskDescription(): string {
    return this.type.toLowerCase() === 'campaign'
      ? 'CAMPAIGN PROSPECT'
      : 'CALLBACK REQUESTED';
  }

  @computedFrom('firstName', 'surname')
  get fullName(): string {
    if (!this.firstName && !this.surname) {
      return '(No Name)';
    }
    if (!this.firstName && this.surname) {
      return this.surname;
    }
    if (this.firstName && !this.surname) {
      return this.firstName;
    } else {
      return this.firstName + ' ' + this.surname;
    }
  }

  @computedFrom('channel', 'type', 'direction', 'duration')
  get channelIcon(): string {
    if (this.channel && this.channel === 'call') {
      if (this.direction === 'OUTBOUND' && this.duration === 0) {
        return 'call-dropped';
      }
      if (this.direction === 'OUTBOUND') {
        return 'call-outbound';
      }
      return 'call-inbound';
    }

    if (this.channel === 'note') {
      return 'feather';
    }

    if (this.channel) {
      if (this.channel === 'email') {
        return 'envelope-closed';
      }

      if (this.channel === 'chat') {
        return 'comments-square';
      }

      if (this.channel === 'sms') {
        return 'comments';
      }

      if (this.channel === 'outbound call' && this.duration === 0) {
        return 'call-dropped';
      }

      if (this.channel === 'outbound call' && this.duration > 0) {
        return 'call-outbound';
      }

      if (this.channel === 'inbound call') {
        return 'call-inbound';
      }

      if (this.channel === 'internal call') {
        return 'call-internal';
      }

      if (this.channel === 'flow call') {
        return 'call';
      }

      if (this.channel === 'outbound flow call') {
        return 'call-outbound';
      }

      if (this.channel === 'inbound flow call') {
        return 'call-inbound';
      }
    }

    if (this.channel === 'campaign') {
      return this.campaignName && this.campaignName.length > 0
        ? 'campaign'
        : 'call-back';
    }

    if (this.channel === 'reminder') {
      return 'calendar';
    }
  }

  @computedFrom('type', 'direction', 'channel')
  get formattedDirection(): string {
    if (this.channel) {
      if (this.channel.includes('outbound') || this.direction === 'OUTBOUND') {
        return 'Outbound';
      }
      if (
        this.channel.includes('inbound') ||
        this.channel.includes('chat') ||
        this.channel === 'campaign' ||
        this.channel === 'callback'
      ) {
        return 'Inbound';
      }
      if (this.type === 'Contact Centre Flow') {
        return 'Inbound';
      }
      if (this.direction) {
        return (
          this.direction.charAt(0).toUpperCase() +
          this.direction.slice(1).toLowerCase()
        );
      }
    }

    if (this.type) {
      return this.type.toLowerCase();
    } else {
      return 'Contact Centre Flow';
    }
  }

  @computedFrom(
    'member',
    'member.firstName',
    'member.surname',
    'agent',
    'agent.firstName',
    'agent.surname'
  )
  get agentName(): string {
    if (this.member) {
      if (!this.member.firstName && !this.member.surname) {
        return '(No Name)';
      }
      if (!this.member.firstName && this.member.surname) {
        return this.member.surname;
      }
      if (this.member.firstName && !this.member.surname) {
        return this.member.firstName;
      } else {
        return this.member.firstName + ' ' + this.member.surname;
      }
    }
    if (this.agent) {
      if (!this.agent.firstName && !this.agent.surname) {
        return '(No Name)';
      }
      if (!this.agent.firstName && this.agent.surname) {
        return this.agent.surname;
      }
      if (this.agent.firstName && !this.agent.surname) {
        return this.agent.firstName;
      } else {
        return this.agent.firstName + ' ' + this.agent.surname;
      }
    } else {
      return '-';
    }
  }

  @computedFrom('member', 'agent')
  get agentFirstName(): string {
    if (this.member) {
      return this.member.firstName;
    }
    if (this.agent) {
      return this.agent.firstName;
    } else {
      return '-';
    }
  }

  @computedFrom('to')
  get interactionTo(): string {
    if (this.to) {
      return this.to;
    }
    return '';
  }

  @computedFrom('channel')
  get formattedChannel(): string {
    if (this.channel === 'email') {
      return 'Email';
    }
    if (this.channel === 'sms') {
      return 'SMS';
    }
    if (
      this.channel === 'inbound call' ||
      this.channel === 'outbound call' ||
      this.channel === 'voice'
    ) {
      return 'call';
    }
    if (this.channel === 'chat') {
      return 'CHAT';
    }
    if (this.channel === 'instant_message') {
      return 'IM';
    }
    return this.channel;
  }

  @computedFrom('metaData')
  get contactFromClickToDial(): string {
    if (this.metaData && this.metaData.contactId) {
      return this.metaData.contactId;
    }
    return null;
  }

  @computedFrom('recordingId')
  get canPlayRecording(): boolean {
    return this.recordingId ? true : false;
  }

  @computedFrom('ccs', 'bccs')
  get emailPayloadDetail(): any[] {
    return [
      {
        label: 'Cc',
        list: this.ccs,
      },
      {
        label: 'Bcc',
        list: this.bccs,
      },
    ];
  }

  private getCurrentFormattedTime(): string {
    const dateTime = moment().format('DD/mm/YYYY hh:mm:ss');
    return dateTime;
  }
}
