import { EventAggregator } from 'aurelia-event-aggregator';
import {
  autoinject,
  bindable,
  computedFrom,
  customElement,
  LogManager,
} from 'aurelia-framework';
import { SessionStore } from 'zailab.common';

import { ChannelService } from '../../channel-service';
import {
  CONTROLLER_ACTIONS,
  LOADER_ACTIONS,
} from '../../../../contact/contactcontroller/contact-controller-actions';
import { FlowsModel } from '../../../../contact/contactcontroller/flows-model';
import { ContactModel } from '../../../../contact/contact-model';
import { TelephoneNumberModel } from '../../../../../../components/atoms/inputs/phonenumber/telephone-number.model';

interface EventSubscriptions {
  contactUpdated: Object;
  clickToDialFailed: Object;
  interactionEnded: Object;
}

const logger = LogManager.getLogger('Call');

@customElement('z-call')
@autoinject()
export class Call {
  @bindable private contactid;
  @bindable private contact: ContactModel;
  @bindable private conversation;
  @bindable private onCall: boolean;
  @bindable public flows: FlowsModel;

  @bindable({ attribute: 'has-outcome' })
  hasOutcome;
  @bindable({ attribute: 'on-wrapup' })
  onWrapup;
  private currentIndexOfRecipientNumber: number = 0;
  private recipientNumber: string = null;
  private recipientItemPosition: string = '';
  private currentIndexOfFlow: number = 0;
  private selectedFlow: Object = {};
  private flowItemPosition: string = '';
  private initialiseText: string;
  private flowsRetrieved: boolean = false;
  private numbersRetrieved: boolean = false;
  private subscriptions: EventSubscriptions = {
    contactUpdated: null,
    clickToDialFailed: null,
    interactionEnded: null,
  };

  constructor(
    private eventAggregator: EventAggregator,
    private channelService: ChannelService,
    private sessionStore: SessionStore
  ) {
    this.eventAggregator = eventAggregator;
    this.channelService = channelService;
    this.sessionStore = sessionStore;
  }

  public attached(): void {
    this.getSelectedFlow();
    if (this.contact.showDispositionCode) {
      this.eventAggregator.publish(
        'change.channel.size.expand',
        this.contact.contactId
      );
      this.eventAggregator.publish(
        'change.channel.size.expand',
        this.contact.contactId
      );
    }
  }

  private getSelectedFlow(): void {
    for (let index = 0; index < this.flows.outboundFlows.length; index++) {
      const flow = this.flows.outboundFlows[index];
      if (flow.selected) {
        this.selectedFlow = flow;
        this.currentIndexOfFlow = index;
      }
    }

    if (!this.selectedFlow) {
      // this.selectedFlow = this.flows.outboundFlows.filter((flow) => flow.selected)[0];
      this.selectedFlow = this.flows.outboundFlows[0];
    }
    this.flowItemPosition = this.animateItemPosition(this.currentIndexOfFlow);

    if (!this.recipientNumber) {
      this.recipientNumber = this.validNumbers[0]
        ? this.validNumbers[0].number
        : null;
    }
  }

  private selectPreviousRecipientNumber(): number {
    return this.setRecipientNumberToDisplay(false);
  }

  private selectNextRecipientNumber(): number {
    return this.setRecipientNumberToDisplay(true);
  }

  private setRecipientNumberToDisplay(shouldIncrement: boolean): number {
    if (
      (!shouldIncrement && this.currentIndexOfRecipientNumber > 0) ||
      (shouldIncrement &&
        this.currentIndexOfRecipientNumber < this.validNumbers.length - 1)
    ) {
      this.currentIndexOfRecipientNumber = shouldIncrement
        ? this.currentIndexOfRecipientNumber + 1
        : this.currentIndexOfRecipientNumber - 1;
      this.recipientItemPosition = this.animateItemPosition(
        this.currentIndexOfRecipientNumber
      );
      this.recipientNumber =
        this.validNumbers[this.currentIndexOfRecipientNumber].formattedNumber;
    } else {
      this.recipientItemPosition = this.animateItemPosition(
        this.currentIndexOfRecipientNumber
      );
      this.recipientNumber =
        this.validNumbers[this.currentIndexOfRecipientNumber].formattedNumber;
    }
    return this.currentIndexOfRecipientNumber;
  }

  private selectPreviousFlow(): number {
    return this.setFlowToDisplay(false);
  }

  private selectNextFlow(): number {
    return this.setFlowToDisplay(true);
  }

  private setFlowToDisplay(shouldIncrement: boolean): number {
    if (
      (shouldIncrement &&
        this.currentIndexOfFlow < this.flows.outboundFlows.length - 1) ||
      (!shouldIncrement && this.currentIndexOfFlow > 0)
    ) {
      this.currentIndexOfFlow = shouldIncrement
        ? this.currentIndexOfFlow + 1
        : this.currentIndexOfFlow - 1;
      this.flowItemPosition = this.animateItemPosition(this.currentIndexOfFlow);
      this.selectedFlow = this.flows.outboundFlows[this.currentIndexOfFlow];
    } else {
      this.flowItemPosition = this.animateItemPosition(this.currentIndexOfFlow);
      this.selectedFlow = this.flows.outboundFlows[this.currentIndexOfFlow];
    }
    this.selectedFlow.selected = true;
    return this.currentIndexOfFlow;
  }

  private animateItemPosition(indexOfItem: number): string {
    let cssTransformValue = -100;
    return 'transform: translateY(' + indexOfItem * cssTransformValue + '%)';
  }

  public initialiseCall(): void {
    if (
      !this.selectedFlow ||
      !this.selectedFlow.flowId ||
      !this.recipientNumber ||
      this.onCall
    ) {
      return;
    }

    this.dial();

    this.initialiseText = 'Initialising Call';

    let intervalTimer = 1000;
    let callInterval = setInterval(() => {
      this.initialiseText = this.initialiseText + '.';
    }, intervalTimer);

    let timer = 4000;
    setTimeout(() => {
      this.initialiseText = '';
      clearInterval(callInterval);
    }, timer);
  }

  private dial(): void {
    let conversation = this.contact.getSelectedConversation();
    if (
      conversation &&
      conversation.status.toLowerCase() !== 'pending' &&
      conversation.status.toLowerCase() !== 'new'
    ) {
      conversation = null;
    }
    let campaignInteraction = this.contact.servedInteractions.find(
      (interaction) => interaction.isTask
    );
    let email = this.sessionStore.get.user.email;

    let call: object = {
      channel: 'CALL',
      contactId: this.contact.contactId,
      conversationId: conversation ? conversation.conversationId : null,
      correlationId: this.contact.correlationId,
      type: 'Outbound',
      campaignId: campaignInteraction
        ? campaignInteraction.payload.metadata.campaignId
        : null,
      campaignName: campaignInteraction
        ? campaignInteraction.payload.metadata.campaignName
        : null,
      prospectListId: campaignInteraction
        ? campaignInteraction.payload.metadata.prospectListId
        : null,
      email,
      flow: this.selectedFlow,
      recipientNumber: this.recipientNumber,
      additionalProperties: campaignInteraction
        ? campaignInteraction.additionalProperties
        : null,
    };

    this.eventAggregator.publish(CONTROLLER_ACTIONS.CLICK_TO_DIAL, call);
  }

  @computedFrom('contact', 'contact.validNumbers')
  private get validNumbers(): Array<TelephoneNumberModel> {
    let validNumbers = [];
    if (this.contact.contactId) {
      validNumbers = this.contact.validNumbers;
    } else if (this.contact.servedInteractions.length > 0) {
      if (this.contact.servedInteraction.from) {
        let re =
          /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
        if (re.test(this.contact.servedInteraction.from)) {
          return validNumbers;
        }
      }

      let telephoneNumber =
        this.contact.servedInteraction.from ||
        this.contact.servedInteraction.sourceNumber;
      if (this.contact.servedInteraction.formattedDirection === 'Outbound') {
        telephoneNumber = this.contact.servedInteraction.interactionTo;
      }
      if (telephoneNumber && !telephoneNumber.isDnc) {
        let region = this.sessionStore.get.organisation.country.code;
        validNumbers = [
          new TelephoneNumberModel(telephoneNumber, '', region, false, false),
        ];
      }
    }
    if (!this.recipientNumber && validNumbers && validNumbers[0]) {
      this.recipientNumber = validNumbers[0].number;
    }
    return validNumbers;
  }

  @computedFrom('selectedFlow', 'recipientNumber', 'onCall', 'contact')
  private get isHoverable(): boolean {
    return (
      this.selectedFlow &&
      this.selectedFlow.flowId &&
      (this.recipientNumber ? true : false)
    );
  }

  public detached(): void {
    this.numbersRetrieved = false;
    this.flowsRetrieved = false;
    this.recipientNumber = null;
    this.selectedFlow = {};
  }
}
