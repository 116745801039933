import { LogManager } from 'aurelia-framework';
import {
  validateTrigger,
  ValidationController,
  ValidationControllerFactory,
  ValidationRules,
} from 'aurelia-validation';
import { inject } from 'aurelia-dependency-injection';
import { bindable } from 'aurelia-templating';

import { BootstrapFormRenderer, Event } from 'zailab.common';

import './dynamic-table.scss';

const logger = LogManager.getLogger('DynamicTable');

@inject(Element, ValidationControllerFactory)
export class DynamicTable {
  @bindable data = [];
  @bindable types;
  @bindable columns = [];
  @bindable validations;
  @bindable({ attribute: 'max-height' }) maxHeight = '';
  @bindable({ attribute: 'min-height' }) minHeight = '';
  @bindable({ attribute: 'disable-actions' }) disableActions: boolean;
  @bindable({ attribute: 'disable-add' }) disableAdd: boolean;
  @bindable({ attribute: 'disable-edit' }) disableEdit: boolean;
  @bindable({ attribute: 'disable-inline-edit' }) disableInlineEdit: boolean;
  @bindable({ attribute: 'is-orderable' }) isOrderable = false;
  @bindable({ attribute: 'display-indexes' }) displayIndexes = false;
  @bindable({ attribute: 'field-width' }) fieldWidth = '';
  @bindable private strictRegex = /^(?:(?![+{}()\s\\/]).)*$/;
  @bindable private regex;

  private validation: ValidationController;
  public listContainer: HTMLElement;
  private draggingItem: any;

  public newRowData: { [key: string]: string } = {};
  public validationData: { [key: string]: string } = {};
  public editState: { [key: string]: string[] } = {};
  public errorState: { [key: string]: string[] } = {};

  constructor(
    private element: Element,
    validationControllerFactory: ValidationControllerFactory
  ) {
    this.validation = validationControllerFactory.createForCurrentScope();
    this.validation.addRenderer(new BootstrapFormRenderer());
    this.validation.validateTrigger = validateTrigger.change;
  }

  public bind(): void {
    this.columns.forEach((col, index) => {
      this.newRowData['column--' + index] = '';
    });
    if (this.types) {
      this.types.forEach((type, index) => {
        if (!type) {
          return;
        }
        this.validationData['column--' + index] = type.validation;
      });
    }
  }

  public attached(): void {
    if (this.validations) {
      this.initiateValidations();
    }
  }

  private initiateValidations(): void {

    let rules: any = ValidationRules;
    const columns = Object.keys(this.newRowData);

    if (columns.length > 0) {
      columns.forEach((id, columnIndex) => {
        ValidationRules.customRule(
          'validInput-' + id,
          (value) => {
            if (!this.regex) {
              return true;
            } else if(Array.isArray(this.regex) && this.regex[columnIndex]) {
              return this.regex[columnIndex].test(value);
            }
            logger.warn('No regex specified, using strict.');
            return this.strictRegex.test(value);
          },
          'Special characters are not allowed.'
        );

        rules = rules
          .ensure(id)
          .required()
          .withMessage('Please enter a value.');

        if (!this.validationData[columnIndex]) {
          rules = rules.then().satisfiesRule('validInput-' + id);
        } else {
          if (this.validationData[columnIndex] === 'email') {
            rules = rules.email().withMessage('Please enter a valid email.');
          }
        }

        if (this.validationData[columnIndex] === 'email') {
          rules = rules.email().withMessage('Please enter a valid email.');
        }
      });

      rules.on(this.newRowData);
    }
  }

  public editRow(row: any, index: number): void {
    if (this.disableInlineEdit) {
      new Event(this.element, 'edit', row);
      return;
    }
    this.editState[index] = [...row];
  }

  public validateInput(rowIndex: number, columnIndex: number): void {
    if (columnIndex > 0) {
      return;
    }
    let value = this.editState[rowIndex][columnIndex];
    this.errorState[rowIndex] = null;

    if (!value) {
      if (!this.errorState[rowIndex]) {
        this.errorState[rowIndex] = [];
      }
      this.errorState[rowIndex][columnIndex] = 'Please enter a value.';
    } else if (this.regex) {
      if (Array.isArray(this.regex) && this.regex[columnIndex]) {
        if (!this.regex[columnIndex].test(value)) {
          if (!this.errorState[rowIndex]) {
            this.errorState[rowIndex] = [];
          }
          this.errorState[rowIndex][columnIndex] = 'Special characters are not allowed.';
        }
      } else if (!this.strictRegex.test(value)) {
        if (!this.errorState[rowIndex]) {
          this.errorState[rowIndex] = [];
        }
        this.errorState[rowIndex][columnIndex] = 'Special characters are not allowed.';
      }
    }
  }

  public updateRow(row: string[], index: number): void {
    let data = [...this.editState[index]];

    if (this.errorState[index] && this.errorState[index].length > 0) {
      return;
    }

    this.data.splice(index, 1, data);
    this.editState[index] = null;

    this.triggerUpdate();
  }

  public cancelEditRow(index: number): void {
    this.editState[index] = null;
    this.errorState[index] = null;
  }

  public deleteRow(index: number): void {
    new Event(this.element, 'row-removed', this.data[index]);
    this.data.splice(index, 1);
    this.triggerUpdate();
  }

  public addRow(): void {
    this.validation.validate().then((validationResult) => {
      if (!validationResult.valid) {
        return;
      }
      const uniqueKey = this.types && this.types[0].uniqueKey;
      if (
        uniqueKey &&
        this.data.find((row) => row[0] === this.newRowData['column--0'])
      ) {
        if (this.types) {
          this.types[0].validationError = 'Please enter a unique value.';
        }
        return;
      }
      if (this.types) {
        this.types[0].validationError = '';
      }
      const rowData = Object.values(this.newRowData);
      if (this.types && this.types[0] && this.types[0].type === 'dropdown') {
        let option = this.types[0].options.find(item => item[this.types[0].displayField] === rowData[0]);
        option.disabled = true;
      }
      this.data.push(rowData);
      Object.keys(this.newRowData).forEach((key) => {
        this.newRowData[key] = '';
      });

      this.scrollToBottom();
      this.triggerUpdate();
    });
  }

  public triggerUpdate(): void {
    // publish event from here
    new Event(this.element, 'data-changed', this.data);
  }

  public scrollToBottom(): void {
    // Scroll to the last item in the list
    if (!this.listContainer) {
      return;
    }
    setTimeout(() => {
      const lastItem = this.listContainer.lastElementChild;
      if (lastItem) {
        lastItem.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }, 200);
  }

  // Drag and drop
  public dragStart(event: any, index: number): any {
    if (!this.isOrderable) {
      return;
    }
    event.stopPropagation();
    const item: any = event.target;
    item.style.opacity = '0.3';

    this.draggingItem = item;
    event.dataTransfer.effectAllowed = 'move';
    event.dataTransfer.setData('text/html', item.innerHTML);
    return true;
  }

  public dragOver(event: any): void {
    event.preventDefault();
  }

  public drop(event: any): void {
    const current = this.draggingItem;
    const path = event.composedPath ? event.composedPath() : event.path;
    const item: any = path.find((el) => el.id === 'drag-row');
    const items = item.parentElement.querySelectorAll('.js-draggable');

    event.preventDefault();
    if (item !== current && item.parentElement === current.parentElement) {
      let currentpos = 0,
        droppedpos = 0;
      for (let it = 0; it < items.length; it++) {
        if (current === items[it]) {
          currentpos = it;
        }
        if (item === items[it]) {
          droppedpos = it;
        }
      }
      if (currentpos < droppedpos) {
        item.parentNode.insertBefore(current, item.nextSibling);
      } else {
        item.parentNode.insertBefore(current, item);
      }
    }
  }

  public dragEnd(event: any): any {
    const dropItem: any = event.target;
    dropItem.style.opacity = '1';

    dropItem.parentElement.childNodes.forEach((item) => {
      if (item.classList) {
        item.classList.remove('over');
      }
    });
    return false;
  }

  public dragEnter(event: any): void {
    event.preventDefault();
    const path = event.composedPath ? event.composedPath() : event.path;
    const item: any = path.find((el) => el.id === 'drag-row');
    const current = this.draggingItem;

    if (item && item.parentElement === current.parentElement) {
      if (item.className.indexOf(' over') === -1) {
        item.classList.add('over');
      }
    }
  }

  public dragLeave(event: any): any {
    const path = event.composedPath ? event.composedPath() : event.path;
    const item: any = path.find((el) => el.id === 'drag-row');
    item.classList = item.className.replace(' over', '');
  }

  public doNothing(): void {
    //
  }
}
